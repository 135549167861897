import React from "react"
import {
  Seo,
} from "@components"
import { PageLayout } from "../components/site/layout/page-layout"
import { ThreatPanel } from "../components/site/threats"

import { StaticImage } from "gatsby-plugin-image"

const ThreatsPage = ({ location }) => {
  return (
    <PageLayout location={location} pathname={location.pathname}>
          {/* Big Brand start */}
          <div className="bg-[#122331] w-full min-h-[327px] h-[48vw] md:h-max max-h-[474px]">
            <div className="container flex ">
              <div className="w-full md:w-1/2 relative">
                <h1 className="text-[35px] md:text-[58px] leading-[58px] font-black self-center text-center tracking-tight mt-[60px] sm:mt-[80px] md:mt-[134px] md:ml-[-42px] text-[#acafb3]">The Threats<br></br>of Exposed<br></br> <span className="pb-[29px] rounded-md">Secrets</span></h1>
                <hr className="border-[3.3px] bg-[#b91684] border-[#b91684] w-[40%] m-auto mt-[-17px] md:ml-[27%] ml-[30%]"/>
              </div>
              <div className="hidden md:flex content-center flex-wrap w-1/2 ml-[-67px] mt-[1px] not-prose">
                <StaticImage
                  src={"../components/site/threats/images/Firewalls.png"}
                  className="w-full md:max-w-[490px] h-auto"
                  alt="Mobile cyber security concept; Smartphone, shield  and app icons on digital background | Approov"
                />
              </div>
            </div>
          </div>
          {/* Big Brand end */}

          {/* Blogs start */}
          <div className = "container mb-[4rem]">
            <div className="mt-[20px] mb-[0px] sm:mb-[46px] bg-[#ebebeb] pb-[20px]">
              {/* Title start */}
              <div className="not-prose">
                <p className="pl-[15px] pr-[15px] md:pl-[0px] md:pr-[0px] text-[24px] md:text-[42px] font-black not-prose text-center">Anatomy of a Hack</p>
                <p className="pl-[15px] pr-[15px] md:pl-[0px] md:pr-[0px] text-[18.7px] md:w-[94%] text-center md:text-left md:ml-[24px] mt-[2px] leading-[26px]">Hundreds of millions of API attacks occur each day attempting to steal valuable data, goods, or create accounts that can be exchanged for money. A typical attack sequence involves the following steps.</p>
                <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-2 md:grid-cols-3 md:gap-3 mt-[16px]">
                  <div>
                      <div className="flex justify-center not-prose">
                        <StaticImage
                          src={"../components/site/threats/images/phone1.png"}
                          className="w-[217px]"
                          alt="Layer 17"
                        />
                      </div>
                      <p className="text-[24px] md:text-[42px] font-black not-prose text-center">Analyze</p>
                      <p className="text-[14px] ml-[33px] leading-[19px] text-center w-[80%]">Your app is used to set up an account, then reverse engineered to extract API keys and other secrets while observing and manipulating API calls through your HTTPS/TLS-protected channels.</p>
                  </div>
                  <div>
                      <div className="flex justify-center not-prose">
                        <StaticImage
                          src={"../components/site/threats/images/phone2.png"}
                          className="w-[148px] mt-[64px]"
                          alt="Layer 17"
                        />
                      </div>
                      <p className="text-[24px] md:text-[42px] font-black not-prose text-center">Build</p>
                      <p className="text-[14px] ml-[30px] mt-[-2px] leading-[20px] text-center w-[80%]">The attacker builds scripts or modified/hooked versions of your app to build spoofed but valid requests to suit their purposes.</p>
                  </div>
                  <div>
                      <div className="flex justify-center not-prose">
                        <StaticImage
                          src={"../components/site/threats/images/phone3.png"}
                          className="w-[121px] mt-[46px]"
                          alt="Layer 17"
                        />
                      </div>
                      <p className="text-[24px] md:text-[42px] font-black not-prose text-center">Exploit</p>
                      <p className="text-[14px] ml-[33px] leading-[19px] text-center w-[80%]">The attacker assembles a botnet and exploits your API, adjusting request rates and masking IP locations with VPNs to remain undetected.</p>
                  </div>
                </div>
              </div>
              {/* Title end */}
            </div>

            <div className="mt-[5rem]">
              {/* Title start */}
              <div className="not-prose">
                <p className="pl-[15px] pr-[15px] md:pl-[0px] md:pr-[0px] text-[24px] md:text-[42px] font-black not-prose text-center">Exposed API Keys and Other Secrets Are Dangerous</p>
                <p className="pl-[15px] pr-[15px] md:pl-[0px] md:pr-[0px] text-[18.7px] md:w-[94%] text-center md:text-left md:ml-[33px] mt-[9px] leading-[26px]">API security solutions may employ API keys or client secrets to identify accounts and lock down access to the mobile client. Such secrets can be easily reverse engineered from app code, and then used in attacker scripts to spoof requests as if they were coming from the official mobile app.</p>
                <p className="pl-[15px] pr-[15px] md:pl-[0px] md:pr-[0px] text-[18.7px] md:w-[94%] text-center md:text-left md:ml-[33px] mt-[9px] leading-[26px]">Even if such secrets can be protected at rest in the app code using obfuscation technologies, they still need to be communicated to the backend API service. Thus secrets can be stolen in transit by Man-in-the-Middle (MitM) attacks.</p>
              </div>
              {/* Title end */}
            </div>
            
            <div className="mt-[5rem]">

              {/* Title start */}
              <div className="not-prose">
                <p className="pl-[15px] pr-[15px] md:pl-[0px] md:pr-[0px] text-[24px] md:text-[42px] font-black not-prose text-center">Threats to Your Business from API Abuse</p>
                <p className="pl-[15px] pr-[15px] md:pl-[0px] md:pr-[0px] text-[18.7px] text-center md:text-left md:w-[94%] md:ml-[33px] mt-[9px] leading-[26px]">Exposure of secrets may result in very negative consequences for your business. There is a growing list of security threats can be very damaging if you don’t have protections in place.</p>
              </div>
              {/* Title end */}
            </div>

            {/* Threat Panel Start */}
            <ThreatPanel/>
            {/* Threat Panel End */}
          </div>
          {/* Blogs end */}

    </PageLayout>
  )
}

export default ThreatsPage
